import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Page from '../components/Page';
import RightSidebar from '../components/RightSidebar';
import Sidebar from '../components/Sidebar';

const NotFoundTemplate = ({ data }) => {
  const { title, subtitle, logo } = data.site.siteMetadata;

  return (
    <Layout title={`Not Found - ${title}`} description={subtitle}>
      <Sidebar />
      <Page logo={logo} title="NOT FOUND">
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </Page>
      <RightSidebar />
    </Layout>
  );
};

export const query = graphql`
  query NotFoundQuery {
    site {
      siteMetadata {
        title
        subtitle
        logo {
          title
          subtitle
          path
          width
          height
        }
      }
    }
  }
`;

export default NotFoundTemplate;
